<template>
  <div v-loading.fullscreen.lock="full_loading" id="profile_page">
    <div class="container">
      <div class="profile-container">
        <div class="profile-photo-info">
          <el-avatar @click.native="$router.push('/settings/change-photo')" class="image" shape="circle" :size="85" :src="user_photo"></el-avatar>

          <div class="info">
            <span class="name">{{ user.data.first_name }} {{ user.data.last_name }}</span>
            <!-- If phone and email verified -->
            <div v-if="$store.getters.userClaims.email_verified && $store.getters.userClaims.phone_verified">
              <span class="verification"><font-awesome-icon style="color: green" :icon="['fas', 'check']" />&nbsp;Email & phone verified</span>
              <router-link class="edit-link" to="/settings"><font-awesome-icon :icon="['fas', 'edit']" />&nbsp;Settings</router-link>
            </div>

            <!-- If only email verified -->
            <div v-else-if="$store.getters.userClaims.email_verified">
              <span class="verification"><font-awesome-icon style="color: green" :icon="['fas', 'check']" />&nbsp;Email verified</span>
              <span class="verification"><font-awesome-icon style="color: red" :icon="['fas', 'times']" />&nbsp;Phone not verified</span>
              <router-link class="edit-link" to="/profile-verification?verifications=EMAIL,PHONE,PHOTO&direct=true"><font-awesome-icon :icon="['fas', 'info-circle']" />&nbsp;Verify my profile</router-link>
            </div>

            <!-- If only phone verified -->
            <div v-else-if="$store.getters.userClaims.phone_verified">
              <span class="verification"><font-awesome-icon style="color: red" :icon="['fas', 'times']" />&nbsp;Email not verified</span>
              <span class="verification"><font-awesome-icon style="color: green" :icon="['fas', 'check']" />&nbsp;Phone verified</span>
              <router-link class="edit-link" to="/profile-verification?verifications=EMAIL,PHONE,PHOTO&direct=true"><font-awesome-icon :icon="['fas', 'info-circle']" />&nbsp;Verify my profile</router-link>
            </div>

            <!-- Nothing verified -->
            <div v-else>
              <span class="verification"><font-awesome-icon style="color: red" :icon="['fas', 'times']" />&nbsp;Email & phone not verified</span>
              <router-link class="edit-link" to="/profile-verification?verifications=EMAIL,PHONE,PHOTO&direct=true"><font-awesome-icon :icon="['fas', 'info-circle']" />&nbsp;Verify my profile</router-link>
            </div>
            
          </div>
        </div>
      </div>

      <el-divider></el-divider>

      <div class="commuting-info">
        <span class="small-title">Your commuting info</span>

        <el-card :body-style="{'padding': '10px'}" shadow="never">
          <div class="commuting-info-times">
            <div>
              <span class="info-desc">Go</span>
              <span class="info-data">{{ go_leaving_time }}</span>
            </div>

            <div>
              <span class="info-desc">Back</span>
              <span class="info-data">{{ back_leaving_time }}</span>
            </div>
          </div>

          <div class="commuting-info-days-of-week">
            <span :day-active="user.data.commuting_days_of_week.monday">Mon</span><span :day-active="user.data.commuting_days_of_week.tuesday">Tue</span><span :day-active="user.data.commuting_days_of_week.wednesday">Wed</span><span :day-active="user.data.commuting_days_of_week.thursday">Thu</span><span :day-active="user.data.commuting_days_of_week.friday">Fri</span><span :day-active="user.data.commuting_days_of_week.saturday">Sat</span><span :day-active="user.data.commuting_days_of_week.sunday">Sun</span>
          </div>
        </el-card>
      </div>

      <div class="commuting-buddie">
        <span class="small-title" style="margin-top: 25px;">Your carpooling connections</span>
        
        <!-- Carpooling buddies -->
        <div v-loading="loading" class="carpooling-buddies">
          <el-card v-for="friend in friends" :key="friend.id" style="margin-bottom: 10px" @click.native="onClickCarpoolingBuddy(friend.id)" :body-style="{'padding': '15px'}">
            <div class="carpooling-buddy-body">
              <el-avatar class="carpooling-buddy-image" shape="circle" :size="55" :src="getUserPhoto(friend.id, 128)"></el-avatar>

              <div class="carpooling-buddy-name">
                <span>{{ friend.data.first_name }} {{ friend.data.last_name }}</span>
                <span>{{ friend.data.carpooling_details | driver_passenger_text }}</span>
              </div>

              <div class="carpooling-buddy-action">
                <font-awesome-icon v-if="friend.friendship_details == 'pending'" :icon="['fas', 'ellipsis-v']"></font-awesome-icon>
                <font-awesome-icon @click.stop="contactWhatsapp(friend.data.phone_number)" v-else style="font-size: 30px; color: rgb(79, 206, 93);" :icon="['fab', 'whatsapp']"></font-awesome-icon>
              </div>
            </div>
          </el-card>

          <!-- No friends -->
          <el-card v-if="friends.length < 1" style="margin-bottom: 10px" :body-style="{'padding': '15px'}">
            <span>You have no carpooling connections yet. Once you have connections, you will be able to book trips in the schedule.</span>
          </el-card>
        </div>
      </div>
    </div>

    <modal-view v-model="modal.active" :title="modal.title">
      <div @click="onViewCarpoolingProfile" class="modal-option">
        <div class="modal-option-icon">
          <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
        </div>

        <span class="modal-option-label">View profile</span>
      </div>

      <div @click="onClickCarpoolingBuddyOption('delete')" v-if="modal.type == 'carpool_connection'" class="modal-option">
        <div class="modal-option-icon">
          <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
        </div>

        <span class="modal-option-label">Delete connection</span>
      </div>

      <div @click="onClickCarpoolingBuddyOption('accept')" v-if="modal.type == 'carpool_request'" class="modal-option">
        <div class="modal-option-icon">
          <font-awesome-icon :icon="['fas', 'check']"></font-awesome-icon>
        </div>

        <span class="modal-option-label">Accept connection request</span>
      </div>

      <div @click="onClickCarpoolingBuddyOption('reject')" v-if="modal.type == 'carpool_request'" class="modal-option">
        <div class="modal-option-icon">
          <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
        </div>

        <span class="modal-option-label">Reject connection request</span>
      </div>
    </modal-view>

  </div>
</template>

<script>
import ModalView from '@/components/Modal/Modal';
import moment from 'moment';

export default {
  components: { ModalView },
  data: () => ({
    modal: {
      active: false,
      type: 'carpool_request', /* 'carpool_request', 'carpool_connection' */
      title: '',
      selected_friend: null,
      id: ''
    },
    drawer: false,
    loading: false,
    full_loading: false,
  }),

  methods: {
    onClickCarpoolingBuddy(id) {
      this.modal.id = id;
      let selected_friend = this.friends.find(f => f.id == id);
      this.modal.selected_friend = selected_friend;

      if (selected_friend.friendship_details == 'accepted') {
        this.modal.type = 'carpool_connection';
      } else if (selected_friend.friendship_details == 'pending') {
        this.modal.type = 'carpool_request';
      }

      this.modal.title = 'Edit connection';
      this.modal.active = true;
    },

    onViewCarpoolingProfile() {
      this.$router.push(`carpooling-profile/${this.modal.id}`);
    },

    onClickCarpoolingBuddyOption(action) {
      if (action == 'delete') {
        let response = confirm(`Are you sure you want to delete ${this.modal.selected_friend.data.first_name} as your connection?`);

        if (!response) {
          return this.modal.active = false;
        }
      }
      this.full_loading = true;

      this.SharoAPI({ action: 'setCarpoolingFriend', data: { _id: this.modal.id, action: action } }, true).then(result => {
        // If AUTH_PERMISSION_DENIED
        if (result.data.error && result.data.code == "AUTH_PERMISSION_DENIED") {
          return this.$router.push({ name: result.redirect_name, query: { verifications: result.redirect_verifications, startTitle: '', startMessage: `In order to ${action} a carpooling request, we need to quickly verify your profile.` }});
        }

        if (result.data.error) {
          return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
        }

        this.$message({ type: 'success', showClose: true, message: result.data.message });

          // Analytics
        if (action == 'delete') {
          this.$analytics.logEvent("carpooling_delete_connection", { friend_id: this.modal.id });
        } else if (action == 'accept') {
          this.$analytics.logEvent("carpooling_accept_request", { friend_id: this.modal.id });
        } else if (action == 'reject') {
          this.$analytics.logEvent("carpooling_reject_request", { friend_id: this.modal.id });
        }
      }).finally(_ => {
        this.full_loading = false;
        this.modal.active = false;
      });
    },

    contactWhatsapp(phone_number) {
      if (['iPhone', 'iPad', 'iPod'].includes(navigator.platform)) {
        const win = window.open(`https://wa.me/${phone_number}?text=Hi%20there!`, '_top');
        return win.focus();
      }

      return window.open(`https://wa.me/${phone_number}?text=Hi%20there!`);

      // Analytics
      this.$analytics.logEvent("carpooling_send_message", { friend_id: this.modal.id });
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    user_photo() {
      return this.getUserPhoto(this.$store.getters.userAuth && this.$store.getters.userAuth.uid || '', '128', this.$store.getters.shortId);
    },

    go_leaving_time() {
      return moment(this.$store.getters.user.data.commuting_times.go_leaving_time).format('LT')
    },

    back_leaving_time() {
      return moment(this.$store.getters.user.data.commuting_times.back_leaving_time).format('LT')
    },

    friends() {
      return this.$store.getters.carpooling_friends;
    }
  },

  watch: {
    user: {
      handler() {
        this.$store.dispatch('getCarpoolingFriends')
      },
      immediate: true
    }
  },

  filters: {
    driver_passenger_text(details) {
      if (details.driver && details.passenger) {
        return 'Driver & passenger';
      } else if (details.driver) {
        return 'Driver only';
      } else {
        return 'Passenger only';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/theme/variables.scss";

  .container {
    max-width: 600px;
    
  }

  .el-card:active {
    border-color: $secondary-color;
  }

  .edit-link {
    font-size: 14px;
    color: $secondary-color;
  }

  .profile-photo-info {
    display: flex;
    align-items: center;
    // margin-bottom: 10px;

    .info {
      margin-left: 15px;

      span {
        display: block;
      }

      .name {
        font-size: 20px;
      }
    }
  }

  .commuting-info-times {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5px;

    & > div {
      text-align: center;
    }

    span {
      display: block;
    }

    .info-desc {
      font-size: 14px;
      margin-bottom: -5px;
    }

    .info-data {
      font-size: 22px;
    }
  }

  .commuting-info-days-of-week {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7px;

    span {
      display: block;
      margin: 4px;
      font-size: 16px;
      color: rgb(200, 200, 200);

      &[day-active="true"] {
        color: #13b500;
      }
    }
  }

  .carpooling-buddy-body {
    display: flex;
    align-items: center;

    .carpooling-buddy-image {
      margin-right: 10px;
    }

    .carpooling-buddy-name {
      span:first-child {
        display: block;
        font-size: 17px;
      }

      span:last-child {
        display: block;
        margin-top: -2px;
      }
    }
    
    .carpooling-buddy-action {
      display: flex;
      align-items: center;
      text-align: right;
      margin-left: auto;
      padding: 5px 10px;
      font-size: 18px;
      color: $text-color;
    }
  }

  .carpooling-buddies {
    min-height: 50px;
  }
</style>